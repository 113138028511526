import { useEffect, useState } from 'react'

import UtmContext from './context'

const UtmProvider = ({ children }) => {
  const [utm, setUtm] = useState({})
  useEffect(() => {
    const search = new URLSearchParams(window.location.search)
    setUtm({
      // ref,
      source: search.get('utm_source'),
      medium: search.get('utm_medium'),
      campaign: search.get('utm_campaign'),
    })
  }, [])
  return <UtmContext.Provider value={utm}>{children}</UtmContext.Provider>
}

export default UtmProvider
