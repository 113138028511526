import React from 'react'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  Flex,
  Divider,
  Center,
} from '@chakra-ui/react'

import Link from 'components/Link'
import { StaticImage } from 'gatsby-plugin-image'
import { responsive } from 'contexts/responsive'

const labels = [
  { name: '速配城市設計說明', href: 'https://whatthedata.cc/blog/design-of-citymatch/ ' },
  // { name: '各縣市完整得分', to: '/cities' },
  { name: '完整指標數據及資料來源', href: 'https://whatthedata.cc/blog/full-ref-citymatch/' },
  { name: '再玩一次', to: '/' },
  { name: '分享到 FB', href: 'https://www.facebook.com/sharer/sharer.php?u=https://city-matching.pages.dev/' },
]

const Menu = ({ isOpen, onClose }) => {
  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton color="textColor" />
        <DrawerHeader mt={responsive("-4em", '-5em')}>
          <Box width="70%" mx="auto">
            <StaticImage
              src="./menu_match.png"
              alt="速配城市"
              // placeholder="tracedSVG"
            />
          </Box>
        </DrawerHeader>
        <DrawerBody mt={responsive("-6em", '-9em')} position={'relative'}>
          {labels.map(({ name, to, href }, i) => (
            <Box textAlign={'center'} fontSize="1.375em" fontWeight={700} borderTop={i &&'1px solid'} py="1em" color="textColor" key={i}>
              <Link to={to} href={href}>{name}</Link>
            </Box>
          ))}
        </DrawerBody>
        <DrawerFooter pb="2.5rem" justifyContent="center">
          <Flex alignItems={'center'}>
            <Link href="https://relab.cc/">
              <Box width={'4.75em'}>
                <StaticImage
                  src="./re_lab.png"
                  alt="re:lab logo"
                  placeholder="tracedSVG"
                  layout="fullWidth"
                />
              </Box>
            </Link>
            <Divider mx="1rem" borderLeftWidth={'2px'} height={'1.25rem'} borderColor="black" orientation='vertical' />
            <Link href="https://whatthedata.cc/">
              <Box width={'5.5em'}>
                <StaticImage
                  src="./what_data.png"
                  alt="what-the-data logo"
                  placeholder="tracedSVG"
                  layout="fullWidth"
                />
              </Box>
            </Link>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default Menu
