exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-care-js": () => import("./../../../src/pages/care.js" /* webpackChunkName: "component---src-pages-care-js" */),
  "component---src-pages-cities-js": () => import("./../../../src/pages/cities.js" /* webpackChunkName: "component---src-pages-cities-js" */),
  "component---src-pages-data-js": () => import("./../../../src/pages/data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loading-js": () => import("./../../../src/pages/loading.js" /* webpackChunkName: "component---src-pages-loading-js" */),
  "component---src-pages-maps-js": () => import("./../../../src/pages/maps.js" /* webpackChunkName: "component---src-pages-maps-js" */),
  "component---src-pages-pair-js": () => import("./../../../src/pages/pair.js" /* webpackChunkName: "component---src-pages-pair-js" */),
  "component---src-pages-result-js": () => import("./../../../src/pages/result.js" /* webpackChunkName: "component---src-pages-result-js" */),
  "component---src-templates-share-js": () => import("./../../../src/templates/share.js" /* webpackChunkName: "component---src-templates-share-js" */)
}

