import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql, withPrefix } from 'gatsby'

import useHeader from 'contexts/header/useHeader'

import Header from '../Header'

const Layout = ({ children, pageContext }) => {
  const { hideHeader } = useHeader()
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
              siteUrl
            }
          }
        }
      `}
      render={({
        site: {
          siteMetadata: { title, description, siteUrl },
        },
      }) => {
        return (
          <>
            {!pageContext?.pagePath?.startsWith('/share') && (
              <Helmet>
                <title>{title}</title>
                <meta property="og:url" content={siteUrl} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={`${siteUrl}/fb.png`} />
              </Helmet>
            )}
            <Helmet>
              <html lang="zh-Hant-TW" />
              <meta name="description" content={description} />
              <link
                rel="apple-touch-icon"
                sizes="180x180"
                href={withPrefix('/apple-touch-icon.png')}
              />
              <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href={withPrefix('/favicon-32x32.png')}
              />
              <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href={withPrefix('/favicon-16x16.png')}
              />
              <link
                rel="mask-icon"
                color="#5bbad"
                href={withPrefix('/safari-pinned-tab.svg')}
              />
              <meta name="msapplication-TileColor" content="#da532c" />
              {/* <meta name="viewport" content="width=device-width" /> */}
              <meta name="theme-color" content="#ffffff" />
            </Helmet>
            {!hideHeader && <Header siteTitle={title} />}
            <main>{children}</main>
          </>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
