import React from 'react'
import { Global } from '@emotion/react'

import { MediaContextProvider } from 'contexts/responsive'
import HeaderProvider from 'contexts/header/Provider'
import globalStyles from './global-styles'
import FirebaseProvider from 'contexts/firebase/provider'
import UtmProvider from 'contexts/utm/Provider'

const Providers = ({ element }) => (
  <FirebaseProvider>
    <MediaContextProvider>
      <HeaderProvider>
        <UtmProvider>
          <>
            <Global styles={globalStyles} />
            {element}
          </>
        </UtmProvider>
      </HeaderProvider>
    </MediaContextProvider>
  </FirebaseProvider>
)
export default Providers
