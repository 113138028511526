import React from 'react'
import { Box, Flex, Icon, useDisclosure,  } from '@chakra-ui/react';
import { AiOutlineMenu } from "react-icons/ai";
import { StaticImage } from 'gatsby-plugin-image'
import { responsive } from 'contexts/responsive';

import Link from 'components/Link'
import Menu from './Menu';

const Header = ({ siteTitle, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Flex
      as="header"
      position="fixed"
      top={0}
      left={0}
      right={0}
      alignItems="center"
      justifyContent={'space-between'}
      zIndex="docked"
      height="header"
      px="1em"
      py="0.5em"
      color={'white'}
      {...props}
    >
      <Box>
        <Link to="/">
          <Box width={responsive('5.5rem', '7rem')}>
            <StaticImage
              src="./header_logo.png"
              alt="速配城市"
              placeholder="tracedSVG"
            />
          </Box>
        </Link>
      </Box>
      <Box onClick={onOpen} cursor="pointer">
        <Icon as={AiOutlineMenu} w={responsive(6, 8)} h={responsive(6, 8)} />
      </Box>
      <Menu isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}

export default Header
