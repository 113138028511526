import { theme, extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools"
import get from "lodash/get";
import breakpoints from "contexts/responsive/breakpoints";
import { responsive } from "contexts/responsive";

const font = 'Noto Sans TC, Arial, "PingFang TC", "HeiTi TC", "Microsoft JhengHei", sans-serif';
const fonts = {
  heading: font,
  body: font,
  serif: '"Noto Serif TC", serif',
  mono: "Menlo, monospace",
}

const colorScheme = 'pink';

const overrides = {
  fonts,
  colors: {
    ...theme.colors,
    text: get(theme.colors, 'black'),
    textColor: '#c43e45',
    textGray: '#A9AAAA',
    dividerColor: '#842A2E',
    borderPink: '#B03E41',
    checkboxBg: '#DFE0E0',
    arrowColor: '#C8C2C3',
    promoteBg: '#F1F1F1',
    textDarkGray: '#4D4D4D',
    careGray: '#767676',
    palePink: '#F6A5AA'
  },
  breakpoints: createBreakpoints(breakpoints),
  sizes: {
    header: '4em',
  },
  components: {
    Button: {
      defaultProps: {
        colorScheme,
      },
      variants: {
        solid: {
          border: '1px solid white',
          color: 'white',
          bgGradient: 'linear(to-r, #F19096, #F6A5AA)',
          px: '2.375rem',
          py: '0.625rem',
          fontSize: responsive('1.125rem', '1.25rem'),
          width: 'auto',
          height: 'auto',
          borderRadius: 'full',
          letterSpacing: '3.6px',
          _hover: {
            bgGradient: 'linear(to-r, #F19096, #F6A5AA)'
          },
          _active: {
            bgGradient: 'linear(to-r, #F19096, #F6A5AA)'
          },
          _before: {
            content: "''",
            pos: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            bgGradient: 'linear(to-r, #F19096, #F6A5AA)',
            filter: 'blur(5px)',
            borderRadius: 'full',
            zIndex: -1,
          }
        },
        ghost: {
          color: '#C63E45',
        }
      }
    },
    Container: {
      baseStyle: {
        maxW: '32em',
        pos: 'relative',
        overflow: 'hidden',
        px: 0
      },
    },
  },
  colorScheme,
}

const customTheme = extendTheme(overrides)

export default customTheme
