const firebaseConfig = {
  apiKey: "AIzaSyC0Vyt31szoof7fZBwEtMdNOUDgTY_qG48",
  authDomain: "what-the-data.firebaseapp.com",
  databaseURL: "https://what-the-data-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "what-the-data",
  storageBucket: "what-the-data.appspot.com",
  messagingSenderId: "484367719776",
  appId: "1:484367719776:web:ffac008ec7adc45d545205"
};

export default firebaseConfig;
