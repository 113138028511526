import React, { useMemo } from 'react'
import { initializeApp } from 'firebase/app'

import firebaseConfig from './config'
import context from './context'

const FirebaseProvider = ({ children }) => {
  const app = useMemo(() => initializeApp(firebaseConfig), [])
  return <context.Provider value={app}>{children}</context.Provider>
}

export default FirebaseProvider
